.app-item-card{
  background: #F6F9FE;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  border-radius: 5px;
  padding: 12px;
}
.app-item-card:not(:first-child){
  margin-top: 12px;
}
.app-item-card-wrap{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.app-item-card-icon{
  --adm-image-width: 40px;
  --adm-image-height: 40px;
  border-radius: 8px;
  overflow: hidden;
}
.app-item-card-right{
  flex: 1;
  display: flex;
  align-items: center;
  padding: 12px 0;
  margin-left: 8px;
}
.app-item-card-content{
  flex: 1;
}
.app-item-card-name{
  font-size: 14px;
  color: #333333;
  line-height: 22px;
  font-weight: 600;
  height: 22px;
}
.app-item-card-download-num{
  font-size: 12px;
  color: #666666;
  line-height: 20px;
  font-weight: 400;
  margin-top: 4px;
}
.app-item-card-desc{
  font-size: 14px;
  color: #666666;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 400;
}