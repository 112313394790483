.image-wrap{
  width: min-content;
  padding: 10px;
}
.detail-wrap{
  padding: 0 15px;
}
.detail-head-wrap{
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #e6e6e6;
}
.detail-head-icon{
  --adm-image-width: 96px;
  --adm-image-height: 96px;
}
.detail-head-info{
  margin-left: 12px;
  flex: 1;
}
.detail-head-app-name{
  font-size: 18px;
  color: #333333;
  line-height: 26px;
  font-weight: 600;
}
.detail-head-download-num{
  font-size: 12px;
  color: #666666;
  line-height: 20px;
  font-weight: 400;
  margin-top: 4px;
}
.detail-head-operate-line{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.detail-head-share-button{
  /* --adm-image-width: 16px;
  --adm-image-height: 16px; */
  cursor: pointer;
}
.detail-desc{
  padding: 12px 0;
  font-size: 14px;
  color: #666666;
  line-height: 22px;
  font-weight: 400;
  border-bottom: 1px solid #e6e6e6;
}
.detail-lastversion{
  padding: 12px 0;
}
.detail-lastversion-title-wrap{
  display: flex;
  justify-content: space-between;
}
.detail-lastversion-title{
  font-size: 18px;
  color: #333333;
  letter-spacing: 0;
  line-height: 26px;
  font-weight: 600;
}
.detail-lastversion-more{
  font-size: 14px;
  color: #386BD7;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 400;
  cursor: pointer;
}
.detail-lastversion-value{
  font-size: 14px;
  color: #999999;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 400;
  flex-shrink: 0;
}
.detail-lastversion-desc{
  margin-top: 20px;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 400;
}
.share-title-line{
  padding: 11px 15px;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #333333;
  line-height: 22px;
  font-weight: 400;
}
.share-button-wrap{
  display: flex;
  padding: 33px 16px;
}
.share-button-item{
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.share-button-item-icon{
  --adm-image-width: 16px;
  --adm-image-height: 16px;
  width: 44px;
  height: 44px;
  background: #F6F6F6;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share-button-item-text{
  font-size: 12px;
  color: #666666;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  margin-top: 20px;
}
.qrcode-dialog-title-line{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialog-content-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 56px;
}
.qrcode-tip{
  margin-top: 8px;
  font-size: 14px;
  color: #999999;
  text-align: center;
  line-height: 22px;
  font-weight: 400;
}
.dialog-button-line{
  display: flex;
  align-items: center;
  margin-top: 66px;
}
.dialog-button-cancel{
  flex: 1;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  font-size: 16px;
  color: #666666;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
}
.dialog-button-save{
  flex: 1;
  height: 44px;
  margin-left: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #386BD7;
  border-radius: 5px;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  font-weight: 400;
}