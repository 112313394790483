.chairman-reader-wrap{
  --gap-horizontal:0px;
  --gap-vertical:20px;
}
.chairman-reader-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
}
.reader-item-icon-wrap{
  --adm-image-width: 80px;
  --adm-image-height: 80px;
}
.reader-item-name{
  margin: 10px 0;
  font-size: 14px;
}