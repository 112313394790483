@import url('iconfont/iconfont.css');

.search-head {
  padding: 9px 15px;
  position: fixed;
  top: 0;
  width: calc(100% - 30px);
  background-color: #fff;
  z-index: 1;
  display: flex;
  justify-content: space-between;
}

.search-head .search-head-search {
  --border-radius: 16px;
  --background: #f9f9f9;
  border-radius: 16px;
  background: #f9f9f9;
  border: 1px solid #dddddd;
  flex: 1;
}

.adm-tabs{
  background-color: #fff;
  margin-bottom: 0.25rem;
}
.adm-tabs .adm-tabs-tab{
  font-size: 14px;
}
.adm-tabs .adm-tabs-tab-active {
  color: #21252E;
  font-weight: bold;
}
.adm-tabs .adm-tabs-tab-line{
  height: 3px;
  border: none;
}

.adm-tabs .adm-tabs-header {
  border: none;
}