.share-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.share-head-icon {
  --adm-image-width: 96px;
  --adm-image-height: 96px;
}
.btn {
  position: relative;
  padding: 0.75rem 1.625rem;
  margin: 0.75rem 0;
  width: 18rem;
  border-radius: 2rem;
  background: #1c7ed9;
  color: #fff;
  cursor: pointer;
}