@font-face {
  font-family: "iconfont"; /* Project id 4311096 */
  src: url('iconfont.woff2?t=1698977210162') format('woff2'),
       url('iconfont.woff?t=1698977210162') format('woff'),
       url('iconfont.ttf?t=1698977210162') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
  content: "\e6a4";
}

.icon-dengpao:before {
  content: "\e62a";
}

.icon-dengpao-tianchong:before {
  content: "\e62b";
}

.icon-home:before {
  content: "\e603";
}

