.app-list{
  padding: 0 15px;
}
.app-list-top3-wrap{
  background: url("assets/bg-top3.png");
  background-size: 100% 100%;
  background-repeat:no-repeat;
  height: 190px;
  position: relative;
  margin-bottom: 50px;
}
.app-list-top3-content{
  background: url("assets/bg-top3-content.png");
  background-size: 100% 100%;
  background-repeat:no-repeat;
  height: 216px;
  position: absolute;
  top: 0px;
  left: 10px;
  right: 10px;
  bottom: -26px;
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}
.app-item-top1{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 30%;
  height: 100%;
  margin: 0 5%;
  align-items: center;
  cursor: pointer;
}
.app-item-top1-icon-wrap{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 34px;
}
.app-item-top1-icon{
  --adm-image-width: 48px;
  --adm-image-height: 48px;
  border-radius: 5px;
}
.app-item-top1-icon-top{
  position: absolute;
  --adm-image-width: 20px;
  --adm-image-height: 20px;
  top: -10px;
  left: -10px;
}
.app-item-top1-icon-bottom{
  position: absolute;
  --adm-image-width: 96px;
  --adm-image-height: 34px;
  top: 40px;
}
.app-item-top2{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 30%;
  height: 100%;
  align-items: center;
  margin-left: 3%;
  cursor: pointer;
}
.app-item-top3{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 30%;
  height: 100%;
  align-items: center;
  margin-right: 3%;
  cursor: pointer;
}
.app-item-top2-icon-wrap{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.app-item-top2-icon{
  --adm-image-width: 40px;
  --adm-image-height: 40px;
  border-radius: 5px;
}
.app-item-top2-icon-top{
  position: absolute;
  --adm-image-width: 16px;
  --adm-image-height: 16px;
  top: -8px;
  left: -8px;
}
.app-item-top2-icon-bottom{
  position: absolute;
  --adm-image-width: 78px;
  --adm-image-height: 30px;
  top: 35px;
}