.chairman-download-button{
  min-width: 80px;
  height: 30px;
  background: #E8F0FD;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.chairman-download-button-text{
  font-size: 14px;
  color: #386BD7;
  text-align: center;
  font-weight: 600;
}
.chairman-download-title-line{
  display: flex;
  justify-content: space-between;
  padding: 13px 15px;
  font-size: 16px;
  color: #333333;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  border-bottom: 1px solid #e6e6e6;
}
.chairman-download-button-wrap{
  padding: 0 15px;
}
.chairman-download-tip{
  padding: 16px 0;
  text-align: center;
  font-size: 14px;
  color: #999999;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 400;
}
.chairman-download-button-item{
  padding: 14px 0;
  border-bottom: 1px solid #dddddd;
  font-size: 14px;
  color: #666666;
  letter-spacing: 0;
  font-weight: 400;
}
.chairman-download-button-item:hover{
  color: #386BD7;
}
.wechat-mask{
  position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url('../../assets/tip.png');
    background-repeat: no-repeat;
    background-position: 100% 0;
}
.install-tip-title{
  font-size: 16px;
  color: #333333;
  text-align: center;
  line-height: 24px;
  font-weight: 600;
}
.install-tip-desc{
  font-size: 14px;
  color: #666666;
  line-height: 22px;
  font-weight: 400;
}
.install-tip-step-wrap{
  display: flex;
  align-items: center;
}
.install-tip-step-no{
  height: 22px;
  width: 22px;
  border-radius: 11px;
  background: #386BD7;
  text-align: center;
  color: white;
}
.install-tip-step-content{
  font-size: 14px;
  color: #333333;
  text-align: left;
  line-height: 22px;
  font-weight: 400;
  margin-left: 8px;
}
.install-tip-line{
  height: 32px;
  width: 1px;
  background: rgba(230,230,230,1);
  margin: 8px 10px;
}
.modal-sure{
  color: #386BD7;
  height: 40px;
  background: #E8F0FD;
  border-radius: 2px;
}
.adm-modal-footer .adm-modal-button{
  font-size: 12px;
}
.adm-space-item{
  display: flex;
  justify-content: center;
}