.search-word-item{
  display: flex;
  align-items: center;
  padding: 13px 0;
  margin: 0 16px;
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
}
.search-word-item-text{
  margin-left: 8px;
  font-size: 14px;
  color: #666666;
  line-height: 22px;
  font-weight: 400;
}
.more-use-app-wrap{
  margin: 0 16px;
}
.more-use-app-title{
  padding: 12px 0;
  font-size: 18px;
  color: #333333;
  letter-spacing: 0;
  line-height: 26px;
  font-weight: 600;
}