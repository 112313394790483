.app-item-wrap{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.app-item-icon{
  --adm-image-width: 40px;
  --adm-image-height: 40px;
  border-radius: 8px;
  overflow: hidden;
}
.app-item-right{
  flex: 1;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  padding: 12px 0;
  margin-left: 8px;
}
.app-item-content{
  flex: 1;
}
.app-item-name{
  font-size: 14px;
  color: #333333;
  line-height: 22px;
  font-weight: 600;
  height: 22px;
}
.app-item-download-num{
  font-size: 12px;
  color: #666666;
  line-height: 20px;
  font-weight: 400;
  margin-top: 4px;
}