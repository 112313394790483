.version-item{
  padding: 12px 0;
  margin: 0 15px;
  border-bottom: 1px solid #e6e6e6;
}
.version-item-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.version-item-name{
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 600;
}
.version-item-date{
  font-size: 14px;
  color: #999999;
  text-align: right;
  line-height: 22px;
  font-weight: 400;
  flex-shrink: 0;
}
.version-item-detail{
  margin-top: 8px;
  font-size: 12px;
  color: #666666;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: 400;
  white-space: pre-line;
  word-wrap: break-word;
}