.home {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.home-body {
  /* overflow-y: scroll; */
  padding: 52px 0;
}

.home-bottom {
  border-top: solid 1px var(--adm-color-border);
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
}
.lang-head{
  height: 32px;
  border-radius: 16px;
  background: #f9f9f9;
  border: 1px solid #f9f9f9;
  overflow: hidden;
  margin-left: 15px;
}